import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();

    async function checkAuth() {
        try {
            const check = await fetch(`${process.env.REACT_APP_BACKEND_URL}/profile`, { headers: { "Authorization": `Bearer ${sessionStorage.getItem("token")}` } })
            console.log('Check: ', check)
            if(check.ok) {
                const res = await check.json()
                console.log('Check: ', res)
                if (res.msg !== 'ok') navigate('/login')
            } else{
                navigate('/login')
            }
        } catch(err) {
            console.log(err);
            navigate('/login')
            return;
        }
    }

    useEffect(() => {
        checkAuth();
    }, [])

    return children;
};