import { createSlice } from '@reduxjs/toolkit';

export const courseSlice = createSlice({
    name: 'course',
    initialState: { courseSelected: false, courseDetails: {}, activeVideo: 0, completedVideos: [] },
    reducers: {
        updateCourseSelected: (state, action) => {
            state.courseSelected = action.payload;
        },
        updateCourseDetails: (state, action) => {
            state.courseDetails = action.payload
        },
        updateActiveVideo: (state, action) => {
            state.activeVideo = action.payload;
        },
        updateCompletedVideos: (state, action) => {
            state.completedVideos = action.payload;
        }
    }
});

// this is for dispatch
export const { updateCourseSelected, updateCourseDetails, updateActiveVideo, updateCompletedVideos } = courseSlice.actions;

// this is for configureStore
export default courseSlice.reducer;