import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { updateUser } from "../../features/user/userSlice"
import styled from "./admin.module.css"
import dashboard from '../../dashboard.module.css'
import { useNavigate } from "react-router-dom"
import Notification from "../../utils/notification"

const Admin = () => {
    const [userList, setUserList] = useState([])
    const { user } = useSelector(state => state.user)
    const [addUserModal, setAddUserModal] = useState(false)
    const [addUserEmail, setAddUserEmail] = useState('')
    const addUserEmailRef = useRef()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        getUsers()
        if (!user) getUser()
    }, [])

    useEffect(() => {
        addUserEmailRef.current = addUserEmail;
    }, [addUserEmail])

    async function getUsers() {
        try {
            const check = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users`, { method: 'GET', headers: { "Authorization": `Bearer ${sessionStorage.getItem("token")}` } })
            console.log('Check: ', check)
            if (check.ok) {
                const res = await check.json()
                console.log('Users: ', res)
                if (res.msg) setUserList(res.msg)
            }
        } catch (err) {
            console.log(err);
            return;
        }
    }

    const getUser = async () => {
        try {
            const check = await fetch(`${process.env.REACT_APP_BACKEND_URL}/profile`, { method: 'GET', headers: { "Authorization": `Bearer ${sessionStorage.getItem("token")}` } })
            console.log('Check: ', check)
            if (check.ok) {
                const res = await check.json()
                console.log('Check: ', res)
                if (res.user) dispatch(updateUser(res.user))
                // if (res.msg) window.location.replace(res.msg)
            }
        } catch (err) {
            console.log(err);
            return;
        }
    }

    const addUser = async (event) => {
        try {
            event.preventDefault()
            const add = await fetch(`${process.env.REACT_APP_BACKEND_URL}/register-by-admin`, { method: 'POST', body: JSON.stringify({ email: addUserEmailRef.current }), headers: { "Authorization": `Bearer ${sessionStorage.getItem("token")}`, 'Content-Type': 'application/json' } })
            const res = await add.json();
            if(res.msg==='ok') {
                Notification(styled, 'User added successfully.')
                setAddUserModal(false)
                // navigate(0)
            }
        } catch (err) {
            Notification(styled, 'Email already exists')
            console.log(err);
            return;
        }
    }

    const handleDelete = async (email) => {
        try {
            console.log('email: ', email)
            const check = await fetch(`${process.env.REACT_APP_BACKEND_URL}/delete-user`, { method: 'POST', body: JSON.stringify({ email }), headers: { "Authorization": `Bearer ${sessionStorage.getItem("token")}`, 'Content-Type': 'application/json' } })
            console.log('Check: ', check)
            // if (check.ok) {
            const res = await check.json()
            console.log('Check: ', res)
            if (res.msg === 'ok') navigate(0)
            // if (res.msg) window.location.replace(res.msg)
            // }
        } catch (err) {
            console.log(err);
            return;
        }
    }

    return (
        <>
            {user.isAdmin === 'true' && <section className={`${styled.admin} ${styled.dashboardSection}`} id="admin">
                <div style={{ flexDirection: 'row', justifyContent: 'space-between', height: '12vh' }} className={dashboard.header}>
                    <h1>Admin</h1>
                    <button onClick={() => setAddUserModal(true)} className={styled.addUser}>Add user</button>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>Username</th>
                            <th>Email</th>
                            <th>Access</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userList.map(user => {
                            return <tr>
                                <td>{user.email}</td>
                                <td>{user.email}</td>
                                <td>{user.isPaid === "true" ? <p style={{ color: 'green' }}>Paid</p> : <p style={{color:'red'}}>Registered</p>}</td>
                                <td className={styled.links}>
                                    <button href="" disabled={user.isAdmin === 'true' ? true : false} style={{ background: user.isAdmin === 'true' && 'gray', color: user.isAdmin === 'true' && 'darkgray', cursor: user.isAdmin === 'true'&&'not-allowed' }} onClick={(e) => handleDelete(user.email)} className={styled.btn}>Delete</button>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
                {addUserModal && <div id="demo-modal" class={styled.modal}>
                    <div class={styled.modalContent}>
                        <svg onClick={() => setAddUserModal(false)} width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 5L5 19M5.00001 5L19 19" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <h1>Add User</h1>
                        <form onSubmit={addUser}>
                            <label htmlFor="email">Email</label>
                            <input type="email" value={addUserEmail} onChange={(e) => setAddUserEmail(e.target.value)} />
                            <button type="submit" className="addBtn">Add</button>
                        </form>
                    </div>
                </div>}
            </section>}
        </>
    )
}

export default Admin;