import Navigation from "./Navigation";
import dashboard from '../../dashboard.module.css'
import { useSelector } from "react-redux";
import Nav from "../Course/Nav";
const Layout = ({ children }) => {
    const { courseSelected } = useSelector(state => state.course);

    return (
        <>
            {courseSelected ? <Nav /> : <Navigation />}
            <main className={dashboard.mainDashboard}>{children}</main>
        </>

    )
}

export default Layout;