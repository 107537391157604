export const videos = [
    {
        title: 'Video - 1 (INTRODUCTION)',
        url: "https://iframe.mediadelivery.net/embed/117234/7e26225e-bce0-4601-85f3-710f4df52510?autoplay=false",
        isCompleted: false
    },
    {
        title: 'Video - 2 (TREND)',
        url: "https://iframe.mediadelivery.net/embed/117234/4f271efb-28ea-4bd9-85af-83a52a3a149e?autoplay=false",
        isCompleted: false
    },
    {
        title: 'Video - 3 (MOMENTUM)',
        url: "https://iframe.mediadelivery.net/embed/117234/adeb54b3-951e-411e-b048-e9b7a2b1896f?autoplay=false",
        isCompleted: false
    },
    {
        title: 'Video - 4 (MOMENTUM EXPLAINED)',
        url: "https://iframe.mediadelivery.net/embed/117234/16f5f63e-01bd-4ff2-8e36-fc6ea0bcadd4?autoplay=false",
        isCompleted: false
    },
    {
        title: 'Video - 5 (MOMENTUM+VOLUME)',
        url: "https://iframe.mediadelivery.net/embed/117234/1e2d92da-a917-4901-808d-807c2706844f?autoplay=false",
        isCompleted: false
    },
    {
        title: 'Video - 6 (MOMENTUM+VOLUME EXAMPLE)',
        url: "https://iframe.mediadelivery.net/embed/117234/e9e38eb3-9bc1-42ae-b612-8a38cbf9cd69?autoplay=false",
        isCompleted: false
    },
    {
        title: 'Video - 7 (FIBONNACI)',
        url: "https://iframe.mediadelivery.net/embed/117234/818f63f2-dd15-4fb4-b4cb-a8a9b5b0abe9?autoplay=false",
        isCompleted: false
    },
    {
        title: 'Video - 8 (DISCOUNT ZONE)',
        url: "https://iframe.mediadelivery.net/embed/117234/343bfc68-54a7-4783-aff9-6ce1766eec91?autoplay=false",
        isCompleted: false
    },
    {
        title: 'Video - 9 (PREMIUM ZONE)',
        url: "https://iframe.mediadelivery.net/embed/117234/9607c394-2aab-48b6-96cf-c01e5d92e518?autoplay=false",
        isCompleted: false
    },
    {
        title: 'Video - 10 (STRUCTURE)',
        url: "https://iframe.mediadelivery.net/embed/117234/1ec58ffe-f723-45ba-a2d3-728b8e60b76c?autoplay=false",
        isCompleted: false
    },
    {
        title: 'Video - 11 (IDENTIFY STRUCTURE)',
        url: "https://iframe.mediadelivery.net/embed/117234/a48111f3-1b26-4756-8230-88e586ce2759?autoplay=false",
        isCompleted: false
    },
    {
        title: 'Video - 12 (RANGE & INTERNAL STRUCTURE)',
        url: "https://iframe.mediadelivery.net/embed/117234/9a97f25e-af63-45d5-b048-d81333a8a424?autoplay=false",
        isCompleted: false
    },
    {
        title: 'Video - 13 (IDENTIFY RANGE & INTERNAL STRUCUTRE)',
        url: "https://iframe.mediadelivery.net/embed/117234/b8a50af7-42ea-40cd-9f4d-9717f5ce3da9?autoplay=false",
        isCompleted: false
    },
    {
        title: 'Video - 14 (STF IN BTF)',
        url: "https://iframe.mediadelivery.net/embed/117234/2e8931ca-bb7b-4497-9ae8-ad2edd141c24?autoplay=false",
        isCompleted: false
    },
    {
        title: 'Video - 15 (IDENTIFY STF IN BTF)',
        url: "https://iframe.mediadelivery.net/embed/117234/0c2d98ae-4279-412c-920d-50f2ec756ae2?autoplay=false",
        isCompleted: false
    },
    {
        title: 'Video - 16 (BREAK OF STRUCTURE)',
        url: "https://iframe.mediadelivery.net/embed/117234/18dc0a36-7d0c-4244-bd69-49d288ff1814?autoplay=false",
        isCompleted: false
    },
    {
        title: 'Video - 17 (IDENTIFY BOS)',
        url: "https://iframe.mediadelivery.net/embed/117234/af57986b-b336-4c9a-937b-6c68ff1997d4?autoplay=false",
        isCompleted: false
    },
    {
        title: 'Video - 18 (FOREX EXAMPLE OF BOS)',
        url: "https://iframe.mediadelivery.net/embed/117234/d46b2284-cf59-45a9-8c1c-3bcbb3e88c6e?autoplay=false",
        isCompleted: false
    },
    {
        title: 'Video - 19 (COMBINE EVERYTHING)',
        url: "https://iframe.mediadelivery.net/embed/117234/efab0d0a-59a3-4d90-93a9-32ac03da8d3d?autoplay=false",
        isCompleted: false
    },
    {
        title: 'Video - 20 (COMBINE EVERYTHING WITH EXAMPLE)',
        url: "https://iframe.mediadelivery.net/embed/117234/4969df37-511d-4c58-b5dd-4e598d123071?autoplay=false",
        isCompleted: false
    },
    {
        title: 'Video - 21 (IMBALANCE)',
        url: "https://iframe.mediadelivery.net/embed/117234/0e4842a8-853b-4e1d-85d3-2810774c06b9?autoplay=false",
        isCompleted: false
    },
    {
        title: 'Video - 22 (IMBALANCE EXAMPLE)',
        url: "https://iframe.mediadelivery.net/embed/117234/9bd9a4f1-69db-474d-96e4-f0f0f779012e?autoplay=false",
        isCompleted: false
    },
    {
        title: 'Video - 23 (ORDERBLOCK)',
        url: "https://iframe.mediadelivery.net/embed/117234/34d0e6c5-e45f-401b-92b0-03709410c41b?autoplay=false",
        isCompleted: false
    },
    {
        title: 'Video - 24 (TYPES OF ORDERBLOCK)',
        url: "https://iframe.mediadelivery.net/embed/117234/4df5095b-1a85-4bcd-a1eb-8dce8cf5fe52?autoplay=false",
        isCompleted: false
    },
    {
        title: 'Video - 25 (SELL SCENARIO ORDERBLOCK)',
        url: "https://iframe.mediadelivery.net/embed/117234/c646349b-626a-4713-9d97-73f52a0af0a5?autoplay=false",
        isCompleted: false
    },
    {
        title: 'Video - 26 (BUY SCENARIO ORDERBLOCK)',
        url: "https://iframe.mediadelivery.net/embed/117234/7587326c-860f-4eeb-94a8-f14ec20f26c8?autoplay=false",
        isCompleted: false
    },
    {
        title: 'Video - 27 (LIQUIDITY)',
        url: "https://iframe.mediadelivery.net/embed/117234/0d4c0dd8-85cf-46bc-a123-9421fb635120?autoplay=false",
        isCompleted: false
    },
    {
        title: 'Video - 28 (IDENTIFY LIQUIDITY PART-1)',
        url: "https://iframe.mediadelivery.net/embed/117234/5885b996-3ae9-4bd0-8a81-e877ca63b538?autoplay=false",
        isCompleted: false
    },
    
    {
        title: 'Video - 29 (IDENTIFY LIQUIDITY PART-2)',
        url: "https://iframe.mediadelivery.net/embed/117234/6461e481-5c1b-4f0a-ac83-bd2427c88c14?autoplay=false",
        isCompleted: false
    },
    {
        title: "Video - 30 (Setup's Blueprint)",
        url: "https://iframe.mediadelivery.net/embed/117234/5cf9c851-1880-440e-ac57-8ae7b2ba96ad?autoplay=false",
        isCompleted: false
    },
    {
        title: 'Video - 31 (Entry Module)',
        url: "https://iframe.mediadelivery.net/embed/117234/50d7e577-c680-415a-912e-95e6e67c3f9b?autoplay=false",
        isCompleted: false
    },
    {
        title: 'Video - 32 (Time & Price (Sessions))',
        url: "https://iframe.mediadelivery.net/embed/117234/b5112689-22c2-44a7-86da-6943a862b813?autoplay=false",
        isCompleted: false
    },
    
    
    
    
    
    
];