import styled from './settings.module.css'
import dashboard from '../../dashboard.module.css'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateUser } from '../../features/user/userSlice'
import Notification from '../../utils/notification'

const Settings = () => {
    const { user } = useSelector(state => state.user)
    const [email, setEmail] = useState(user.email ? user.email : '')
    const [password, setPassword] = useState('gbghbhbjkb')
    const initialEmailRef = useRef()
    const [initialPassword, setInitialPassword] = useState('');
    const dispatch = useDispatch()

    useEffect(() => {
        if (!user) getUser()
        else {
            initialEmailRef.current = user.email
        }
    }, [user])

    const handleSubmit = async (type) => {
        try {
            const register = await fetch(`${process.env.REACT_APP_BACKEND_URL}/update-user`, { method: 'PUT', body: JSON.stringify({ initialEmail: initialEmailRef.current, type, value: type === 'email' ? email : password }), headers: { "Authorization": `Bearer ${sessionStorage.getItem("token")}`, 'Content-Type': 'application/json' } });
            const res = await register.json();
            console.log('result: ', res);
            if (res.msg && res.msg === 'ok') {
                if(type === 'email'){
                    Notification(`Email changed successfully, please verify your email.`, styled);
                } else {
                    Notification(`Password changed successfully.`, styled);
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

    const getUser = async () => {
        try {
            const check = await fetch(`${process.env.REACT_APP_BACKEND_URL}/profile`, { method: 'GET', headers: { "Authorization": `Bearer ${sessionStorage.getItem("token")}` } })
            console.log('Check: ', check)
            if (check.ok) {
                const res = await check.json()
                console.log('Check: ', res)
                if (res.user) {
                    setEmail(res.user.email)
                    dispatch(updateUser(res.user))
                }
            }
        } catch (err) {
            console.log(err);
            return;
        }
    }

    const handleInput = (e, type) => {
        const domElem = document.getElementById(`setting-${type}`);
        if (type === 'email') {
            initialEmailRef.current = email
        } else {
            setInitialPassword(password);
            setPassword('');
            domElem.setAttribute('type', 'text');
        }
        e.currentTarget.parentElement.classList.toggle(styled.active);
        domElem.toggleAttribute('readonly');
        domElem.style.border = "1px solid white";
        domElem.focus();
        domElem.setSelectionRange(domElem.value.length, domElem.value.length);
    }

    const handleSave = async (e, field) => {
        const domElem = document.getElementById(`setting-${field}`);
        e.currentTarget.parentElement.classList.toggle(styled.active);
        await handleSubmit(field)
        if (field === "password") {
            domElem.setAttribute('type', 'password');
        }
        domElem.style.border = "none";
        domElem.toggleAttribute("readonly");
    }

    const handleCancel = (e, field) => {
        const domElem = document.getElementById(`setting-${field}`);
        field === 'email' ? setEmail(initialEmailRef.current) : setPassword(initialPassword);
        domElem.style.border = "none";
        e.currentTarget.parentElement.classList.toggle(styled.active);
        domElem.toggleAttribute("readonly");
        if(field === 'password'){
            domElem.setAttribute('type', 'password');
        }
    }

    return (
        <section className={`${styled.dashboardSection} ${styled.settings}`} id="settings">
            <div className={dashboard.header}>
                <h1>Settings</h1>
            </div>
            <div className={styled.details}>
                <div className={`${styled.detailsSection} ${styled.email}`}>
                    <p>Email</p>
                    <div className={styled.emailBox}>
                        <p style={{ marginRight: '10rem'}}>{email}</p>
                        {/* <input type="text" name="email" id="setting-email" onChange={(e) => setEmail(e.target.value)} readonly='readonly' value={email} /> */}
                        {/* <span className={`${styled.status} ${styled.unverified}`}>UNVERIFIED</span> */}
                    </div>
                    {/* <div className={styled.editOptions}>
                        <svg onClick={(e) => handleInput(e, 'email')} width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={styled.trigger}>
                            <path id="Vector" d="M12 8.00012L4 16.0001V20.0001L8 20.0001L16 12.0001M12 8.00012L14.8686 5.13146L14.8704 5.12976C15.2652 4.73488 15.463 4.53709 15.691 4.46301C15.8919 4.39775 16.1082 4.39775 16.3091 4.46301C16.5369 4.53704 16.7345 4.7346 17.1288 5.12892L18.8686 6.86872C19.2646 7.26474 19.4627 7.46284 19.5369 7.69117C19.6022 7.89201 19.6021 8.10835 19.5369 8.3092C19.4628 8.53736 19.265 8.73516 18.8695 9.13061L18.8686 9.13146L16 12.0001M12 8.00012L16 12.0001" stroke="" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <svg onClick={(e) => handleSave(e, 'email')} width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={`${styled.action} ${styled.save}`}>
                            <path d="M4.89163 13.2687L9.16582 17.5427L18.7085 8" stroke="" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <svg onClick={(e) => handleCancel(e, 'email')} fill="#000000" width="30px" height="30px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" className={`${styled.action} ${styled.cancel}`}>
                            <path d="M697.4 759.2l61.8-61.8L573.8 512l185.4-185.4-61.8-61.8L512 450.2 326.6 264.8l-61.8 61.8L450.2 512 264.8 697.4l61.8 61.8L512 573.8z" />
                        </svg>
                    </div> */}
                </div>
                <div className={`${styled.detailsSection} ${styled.password}`}>
                    <p>Password</p>
                    <input type="password" name="password" id="setting-password" minlength="5" onChange={(e) => setPassword(e.target.value)} readonly='readonly' value={password} />
                    <div className={styled.editOptions}>
                        <svg onClick={(e) => handleInput(e, 'password')} width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={styled.trigger}>
                            <path id="Vector" d="M12 8.00012L4 16.0001V20.0001L8 20.0001L16 12.0001M12 8.00012L14.8686 5.13146L14.8704 5.12976C15.2652 4.73488 15.463 4.53709 15.691 4.46301C15.8919 4.39775 16.1082 4.39775 16.3091 4.46301C16.5369 4.53704 16.7345 4.7346 17.1288 5.12892L18.8686 6.86872C19.2646 7.26474 19.4627 7.46284 19.5369 7.69117C19.6022 7.89201 19.6021 8.10835 19.5369 8.3092C19.4628 8.53736 19.265 8.73516 18.8695 9.13061L18.8686 9.13146L16 12.0001M12 8.00012L16 12.0001" stroke="" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <svg onClick={(e) => handleSave(e, 'password')} width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={`${styled.action} ${styled.save}`}>
                            <path d="M4.89163 13.2687L9.16582 17.5427L18.7085 8" stroke="" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <svg onClick={(e) => handleCancel(e, 'password')} fill="#000000" width="30px" height="30px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" className={`${styled.action} ${styled.cancel}`}>
                            <path d="M697.4 759.2l61.8-61.8L573.8 512l185.4-185.4-61.8-61.8L512 450.2 326.6 264.8l-61.8 61.8L450.2 512 264.8 697.4l61.8 61.8L512 573.8z" />
                        </svg>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Settings;