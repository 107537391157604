import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Notification from "../../utils/notification";
import styled from "./forgotPassword.module.css";

const ForgotPassword = () => {
    const [email, setEmail] = useState();
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const register = await fetch(`${process.env.REACT_APP_BACKEND_URL}/forgot-password`, { method:'POST', body: JSON.stringify({email}), headers: {'Content-Type': 'application/json'}});
            const res = await register.json();
            console.log('result: ', res);
            if(res.msg === 'ok'){
                Notification('Password reset link sent. Please check email!', styled)
            }
        } catch(err) {
            console.log(err)
            alert('This email does not exist.')
        }
    };

    return (
        <>
            <video autoplay='autoplay' muted loop>
                <source src="./tradingVideo.mp4" type="video/mp4" />
            </video>
            <div className={styled.image}>
            </div>
            <div className={styled.container}>
                <h1>Reset Password</h1>
                <form onSubmit={handleSubmit}>
                    <div className={styled.formField}>
                        <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                            <label for="email"><span>Email</span></label>
                    </div>
                    <button className={styled.btn} type="submit">Send Reset Link</button>
                </form>
            </div>
        </>
    )
}

export default ForgotPassword;