import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateDarkMode, updateUser } from '../../features/user/userSlice';
import styled from './login.module.css'
import Notification from '../../utils/notification';

const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate();
    const dispatch = useDispatch();

    async function checkAuth() {
        try {
            const check = await fetch(`${process.env.REACT_APP_BACKEND_URL}/profile`, { headers: { "Authorization": `Bearer ${sessionStorage.getItem("token")}` } })
            console.log('Check: ', check)
            if (check.ok) {
                const res = await check.json()
                if (res.msg === 'ok') {
                    Notification('Logged in succesfully', styled)
                    console.log('Login user: ', res.user)
                    dispatch(updateUser(res.user))
                    if (res.user.isAdmin === 'true') navigate('/admin')
                    else navigate('/')
                }
            }
        } catch (err) {
            console.log(err);
            return;
        }
    }

    useEffect(() => {
        checkAuth();
    }, [])

    const handleSubmit = async (event) => {
        console.log('login')
        event.preventDefault();
        console.log('token: ', sessionStorage.getItem("token"))
        const login = await fetch(`${process.env.REACT_APP_BACKEND_URL}/login`, { method: 'post', body: JSON.stringify({ email: email, password: password }), headers: { 'Content-Type': 'application/json' } });
        const res = await login.json();
        if (res.msg === 'ok') {
            document.documentElement.setAttribute('data-theme', 'dark');
            localStorage.setItem('theme', 'dark');
            dispatch(updateDarkMode(true))
            sessionStorage.setItem('token', res.token)
            dispatch(updateUser(res.user))
            if (res.user.isAdmin === 'true') navigate('/admin')
            else navigate('/')
        } else {
            Notification(res.msg, styled)
        }
    }
    return (
        <>
            <video autoplay='autoplay' muted loop>
                <source src="./tradingVideo.mp4" type="video/mp4" />
            </video>
            <div className={styled.container}>
                <h1>Login</h1>
                <form action="" onSubmit={handleSubmit} method="post" className={styled.loginForm}>
                    <div className={styled.formField}>
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} name="email" required />
                        <label for="email"><span>Email</span></label>
                    </div>
                    <div className={styled.formField}>
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} name="password" id="password" minlength="5" required />
                        <label for="password"><span>Password</span></label>
                    </div>
                    <button className={styled.btn} type="submit">Login</button>
                </form>
                <a href="./forgot-password" className={styled.forgot}>Forgot Password?</a>
                <p>Don't have an account? <a className={styled.registerLink} href="./register">Register</a>.</p>
            </div></>
    )

}

export default Login;