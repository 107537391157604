import styled from "./resetPassword.module.css"
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from "react"
import Notification from "../../utils/notification"

const ResetPassword = () => {
    const [queryParameters] = useSearchParams();
    const [validity, setValidity] = useState(false);
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        console.log('id: ', queryParameters.get("email"), ' ', 'toke: ', queryParameters.get("token"))
        checkValidity()
    }, [])

    const checkValidity = async () => {
        try {
            const register = await fetch(`${process.env.REACT_APP_BACKEND_URL}/check-validity`, { method:'POST', body: JSON.stringify({email: queryParameters.get("email"), token: queryParameters.get("token")}), headers: {'Content-Type': 'application/json'}});
            const res = await register.json();
            console.log('result: ', res);
            if(res.msg && res.msg === 'ok') setValidity(true)
        } catch(err) {
            console.log(err)
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const register = await fetch(`${process.env.REACT_APP_BACKEND_URL}/reset-password`, { method:'POST', body: JSON.stringify({email: queryParameters.get("email"), token: queryParameters.get("token"), password}), headers: {'Content-Type': 'application/json'}});
            const res = await register.json();
            console.log('result: ', res);
            if(res.msg && res.msg === 'ok') {
                setPassword('');
                setConfirmPassword('');
                Notification('Password reset successful. Please login.', styled)
                setTimeout(() => {
                    navigate('/login')
                }, 5000)
            }
        } catch(err) {
            console.log(err)
        }
    }
    
    return (
        <>
            <video autoplay='autoplay' muted loop>
                <source src="./tradingVideo.mp4" type="video/mp4" />
            </video>
            <div className={styled.container}>
                <h1>Reset Password</h1>
                <form onSubmit={handleSubmit}>
                    <div className={styled.formField}>
                        <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} id="password" minlength="6" required />
                            <label for="password"><span>Password</span></label>
                    </div>
                    <div className={styled.formField}>
                        <input type="password" name="cpassword" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} id="cpassword" minlength="6" required />
                            <label for="cpassword"><span>Confirm&nbsp;Password</span></label>
                    </div>
                    <button className={styled.btn} type="submit">Reset Password</button>
                </form>
            </div>
        </>
    )
}

export default ResetPassword;