import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from './navigation.module.css'
import sun from './sun.png'
import moon from './moon.png'
import { updateDarkMode } from '../../features/user/userSlice';

const Navigation = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const { user, darkmode } = useSelector(state => state.user);
    const dispatch = useDispatch()
    // const [darkmode, setDarkMode] = useState(false)
    const [hamburgerMenu, setHamburgerMenu] = useState(false)

    useEffect(() => {
        const currentTheme = localStorage.getItem('theme') ? localStorage.getItem('theme') : null;

        if (currentTheme) {
            document.documentElement.setAttribute('data-theme', currentTheme);

            if (currentTheme === 'dark') {
                dispatch(updateDarkMode(true))
            }
        }

        console.log('location: ', location.pathname)
    }, [])

    useEffect(() => {
        console.log('Nav user: ', user)
    }, [user])

    const Logout = async () => {
        try {
            const check = await fetch(`${process.env.REACT_APP_BACKEND_URL}/logout`)
            if (check.ok) {
                const res = await check.json()
                if (res.msg === 'ok') {
                    sessionStorage.removeItem('token')
                    navigate('/login')
                }
                // if (res.message === 'ok') navigate('/login')
            }
        } catch (err) {
            console.log(err);
            return;
        }
    }

    const switchTheme = (e) => {
        console.log(e)
        if (e.target.checked) {
            document.documentElement.setAttribute('data-theme', 'dark');
            localStorage.setItem('theme', 'dark');
            dispatch(updateDarkMode(true))
        }
        else {
            document.documentElement.setAttribute('data-theme', 'light');
            localStorage.setItem('theme', 'light');
            dispatch(updateDarkMode(false))
        }
    }

    return (
        <>
            <div className={hamburgerMenu ? `${styled.active} ${styled.hamburger}` : styled.hamburger} onClick={() =>setHamburgerMenu(prev=>!prev)}>
                <span className={`${styled.line} ${styled.line1} ${hamburgerMenu && styled.active}`}></span>
                <span className={`${styled.line} ${styled.line2} ${hamburgerMenu && styled.active}`}></span>
                <span className={`${styled.line} ${styled.line3} ${hamburgerMenu && styled.active}`}></span>
            </div>
            <div className={hamburgerMenu ? `${styled.active} ${styled.navigation}` : styled.navigation}>
                <div className={styled.logo}>
                    <img src="./1.png" alt="logo" loading='lazy' />
                </div>
                <div className={styled.themeSwitchWrapper}>
                    <label className={styled.themeSwitch} for="checkbox" onChange={switchTheme}>
                        <img src={sun} alt="light" className={styled.sun} loading='lazy' />
                        <img src={moon} alt="" className={styled.moon} loading='lazy' />
                        <input type="checkbox" checked={darkmode} id="checkbox" />
                        <div className={`${styled.slider} ${styled.round}`}></div>
                    </label>
                </div >
                <div className={styled.menu}>
                    <a href="/" className={`${styled.menuItem} ${location.pathname === '/' && styled.active}`}>
                        <svg fill="#000" width="30px" height="30px" viewBox="-4.5 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" className={styled.home}>
                            <path d="M19.469 12.594l3.625 3.313c0.438 0.406 0.313 0.719-0.281 0.719h-2.719v8.656c0 0.594-0.5 1.125-1.094 1.125h-4.719v-6.063c0-0.594-0.531-1.125-1.125-1.125h-2.969c-0.594 0-1.125 0.531-1.125 1.125v6.063h-4.719c-0.594 0-1.125-0.531-1.125-1.125v-8.656h-2.688c-0.594 0-0.719-0.313-0.281-0.719l10.594-9.625c0.438-0.406 1.188-0.406 1.656 0l2.406 2.156v-1.719c0-0.594 0.531-1.125 1.125-1.125h2.344c0.594 0 1.094 0.531 1.094 1.125v5.875z"></path>
                        </svg>
                        <p>Home</p>
                    </a>
                    {user.isAdmin === 'true' && <a href="/admin" className={`${styled.menuItem} ${location.pathname === '/admin' && styled.active}`}>
                        <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={styled.admin}>
                            <path d="M20.9098 11.1203V6.73031C20.9098 5.91031 20.2898 4.98031 19.5198 4.67031L13.9498 2.39031C12.6998 1.88031 11.2898 1.88031 10.0398 2.39031L4.46984 4.67031C3.70984 4.98031 3.08984 5.91031 3.08984 6.73031V11.1203C3.08984 16.0103 6.63984 20.5903 11.4898 21.9303C11.8198 22.0203 12.1798 22.0203 12.5098 21.9303C17.3598 20.5903 20.9098 16.0103 20.9098 11.1203ZM12.7498 12.8703V15.5003C12.7498 15.9103 12.4098 16.2503 11.9998 16.2503C11.5898 16.2503 11.2498 15.9103 11.2498 15.5003V12.8703C10.2398 12.5503 9.49984 11.6103 9.49984 10.5003C9.49984 9.12031 10.6198 8.00031 11.9998 8.00031C13.3798 8.00031 14.4998 9.12031 14.4998 10.5003C14.4998 11.6203 13.7598 12.5503 12.7498 12.8703Z" />
                        </svg>
                        <p>Admin</p>
                    </a>}
                    <a href="/settings" className={`${styled.menuItem} ${location.pathname === '/settings' && styled.active}`}>
                        <svg fill="#000000" width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="settings" className={styled.settings}>
                            <path d="M20.89,9.78h-.65a1.16,1.16,0,0,1-1-.74V9a1.13,1.13,0,0,1,.22-1.26l.46-.46a1.13,1.13,0,0,0,0-1.58L18.29,4.14a1.13,1.13,0,0,0-1.58,0l-.46.46A1.13,1.13,0,0,1,15,4.82h0a1.16,1.16,0,0,1-.74-1V3.11A1.11,1.11,0,0,0,13.11,2H10.89A1.11,1.11,0,0,0,9.78,3.11v.65a1.16,1.16,0,0,1-.74,1H9A1.13,1.13,0,0,1,7.75,4.6l-.46-.46a1.13,1.13,0,0,0-1.58,0L4.14,5.71a1.13,1.13,0,0,0,0,1.58l.46.46A1.13,1.13,0,0,1,4.82,9V9a1.16,1.16,0,0,1-1,.74H3.11A1.11,1.11,0,0,0,2,10.89v2.22a1.11,1.11,0,0,0,1.11,1.11h.65a1.16,1.16,0,0,1,1,.74v0a1.13,1.13,0,0,1-.22,1.26l-.46.46a1.13,1.13,0,0,0,0,1.58l1.57,1.57a1.13,1.13,0,0,0,1.58,0l.46-.46A1.13,1.13,0,0,1,9,19.18H9a1.16,1.16,0,0,1,.74,1v.65A1.11,1.11,0,0,0,10.89,22h2.22a1.11,1.11,0,0,0,1.11-1.11v-.65a1.16,1.16,0,0,1,.74-1h0a1.13,1.13,0,0,1,1.26.22l.46.46a1.13,1.13,0,0,0,1.58,0l1.57-1.57a1.13,1.13,0,0,0,0-1.58l-.46-.46A1.13,1.13,0,0,1,19.18,15v0a1.16,1.16,0,0,1,1-.74h.65A1.11,1.11,0,0,0,22,13.11V10.89A1.11,1.11,0,0,0,20.89,9.78ZM12,16a4,4,0,1,1,4-4A4,4,0,0,1,12,16Z"></path>
                        </svg>
                        <p>Settings</p>
                    </a>
                    <a href="/support" className={`${styled.menuItem} ${location.pathname === '/support' && styled.active}`}>
                        <svg width="30px" height="30px" viewBox="0 0 24 24" fill="#000" xmlns="http://www.w3.org/2000/svg" className={styled.support} >
                            <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zM7.92 9.234v.102a.5.5 0 0 0 .5.5h.997a.499.499 0 0 0 .499-.499c0-1.29.998-1.979 2.34-1.979 1.308 0 2.168.689 2.168 1.67 0 .928-.482 1.359-1.686 1.91l-.344.154C11.379 11.54 11 12.21 11 13.381v.119a.5.5 0 0 0 .5.5h.997a.499.499 0 0 0 .499-.499c0-.516.138-.723.55-.912l.345-.155c1.445-.654 2.529-1.514 2.529-3.39v-.103c0-1.978-1.72-3.441-4.164-3.441-2.478 0-4.336 1.428-4.336 3.734zm2.58 7.757c0 .867.659 1.509 1.491 1.509.85 0 1.509-.642 1.509-1.509 0-.867-.659-1.491-1.509-1.491-.832 0-1.491.624-1.491 1.491z" />

                        </svg>
                        <p>Support</p>
                    </a>
                    <button onClick={Logout} className={`${styled.menuItem} ${styled.logout}`}>
                        <svg width="30px" height="30px" viewBox="0 0 24 24" fill="#000" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.2929 14.2929C16.9024 14.6834 16.9024 15.3166 17.2929 15.7071C17.6834 16.0976 18.3166 16.0976 18.7071 15.7071L21.6201 12.7941C21.6351 12.7791 21.6497 12.7637 21.6637 12.748C21.87 12.5648 22 12.2976 22 12C22 11.7024 21.87 11.4352 21.6637 11.252C21.6497 11.2363 21.6351 11.2209 21.6201 11.2059L18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289C16.9024 8.68342 16.9024 9.31658 17.2929 9.70711L18.5858 11H13C12.4477 11 12 11.4477 12 12C12 12.5523 12.4477 13 13 13H18.5858L17.2929 14.2929Z" />
                            <path d="M5 2C3.34315 2 2 3.34315 2 5V19C2 20.6569 3.34315 22 5 22H14.5C15.8807 22 17 20.8807 17 19.5V16.7326C16.8519 16.647 16.7125 16.5409 16.5858 16.4142C15.9314 15.7598 15.8253 14.7649 16.2674 14H13C11.8954 14 11 13.1046 11 12C11 10.8954 11.8954 10 13 10H16.2674C15.8253 9.23514 15.9314 8.24015 16.5858 7.58579C16.7125 7.4591 16.8519 7.35296 17 7.26738V4.5C17 3.11929 15.8807 2 14.5 2H5Z" />
                        </svg>
                        <p>Logout</p>
                    </button>
                </div >
            </div >
        </>
    )
}

export default Navigation;