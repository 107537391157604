import logo from './logo.svg';
import './App.css';
import Register from './components/Auth/register';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Login from './components/Auth/login';
import ForgotPassword from './components/Auth/forgotPassword';
import ResetPassword from './components/Auth/resetPassword';
import Layout from './components/Layout/Layout';
import { useEffect, useState } from 'react';
import { ProtectedRoute } from './components/Auth/Protected';
import Payment from './components/Billing/Payment';
import Admin from './components/Admin/Admin';
import Settings from './components/Settings/Settings';
import Support from './components/Support/Support';
import Dashboard from './components/Dashboard/Dashboard';

const router = createBrowserRouter([
  {
    path: "/",
    element: <ProtectedRoute><Layout> <Dashboard /></Layout></ProtectedRoute>,
  },
  {
    path: "/admin",
    element: <ProtectedRoute><Layout> <Admin /></Layout></ProtectedRoute>,
  },
  {
    path: "/login",
    element: <Login />,

  },
  {
    path: "/register",
    element: <Register />
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />
  },
  {
    path: "/reset-password",
    element: <ResetPassword />
  },
  {
    path: "/settings",
    element: <ProtectedRoute><Layout><Settings /></Layout></ProtectedRoute>
  },
  {
    path: "/support",
    element: <ProtectedRoute><Layout><Support /></Layout></ProtectedRoute>
  },
]);

function App() {

  return (
    <div className="App">
        <RouterProvider router={router} />
    </div>
  );
}

export default App;
