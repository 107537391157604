import styled from './support.module.css'
import dashboard from '../../dashboard.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { updateUser } from '../../features/user/userSlice'
import { useEffect } from 'react'

const Support = () => {
    const { user } = useSelector(state => state.user)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!user) getUser()
    }, [])

    const getUser = async () => {
        try {
            const check = await fetch(`${process.env.REACT_APP_BACKEND_URL}/profile`, { method: 'GET', headers: { "Authorization": `Bearer ${sessionStorage.getItem("token")}` } })
            console.log('Check: ', check)
            if (check.ok) {
                const res = await check.json()
                console.log('Check: ', res)
                if (res.user) {
                    dispatch(updateUser(res.user))
                }
            }
        } catch (err) {
            console.log(err);
            return;
        }
    }
    return (
        <section className={`${styled.dashboardSection} ${styled.support}`} id="support">
            <div className={dashboard.header}>
                <h1>Support</h1>
            </div>
            <div className={styled.supportSection}>
                <p>If you're facing any issues regarding billing, course content, etc., please email us at : <a href="mailto:punjabismt@gmail.com">punjabismt@gmail.com</a>.</p>
            </div>
        </section>
    )
}

export default Support;