import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { updateUser } from '../../features/user/userSlice';
import styled from './dashboard.module.css';
import dashboard from '../../dashboard.module.css'
import Notification from '../../utils/notification';
import { updateCourseDetails, updateCourseSelected } from '../../features/course/courseSlice';
import Course from '../Course/Course';

const courseArr = [
    {
        title: 'Smart Money Concepts - Beginner to Advanced (Punjabi)',
        isCompleted: false,
        thumbnail: './img/course.jpeg'
    }
]

const Dashboard = () => {
    const { user } = useSelector(state => state.user)
    const { courseSelected } = useSelector(state => state.course)
    const dispatch = useDispatch();
    const [queryParameters] = useSearchParams();

    useEffect(() => {
        getUser()
        if (queryParameters.get("payment_status")) {
            if (queryParameters.get("payment_status") === 'success') {
                Notification('Payment successful', styled)
            } else {
                console.log('Payment Failed')
            }
        }
    }, [])

    const getUser = async () => {
        try {
            const check = await fetch(`${process.env.REACT_APP_BACKEND_URL}/profile`, { method: 'GET', headers: { "Authorization": `Bearer ${sessionStorage.getItem("token")}` } })
            if (check.ok) {
                const res = await check.json()
                if (res.user) dispatch(updateUser(res.user))
            }
        } catch (err) {
            console.log(err);
            return;
        }
    }

    async function getSession() {
        try {
            const check = await fetch(`${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`, { method: 'POST', body: JSON.stringify({ email: user.email }), headers: { "Authorization": `Bearer ${sessionStorage.getItem("token")}`, 'Content-Type': 'application/json' } })
            console.log('Check: ', check)
            if (check.ok) {
                const res = await check.json()
                console.log('Check: ', res)
                if (res.msg) window.location.replace(res.msg)
            }
        } catch (err) {
            console.log(err);
            return;
        }
    }

    if(courseSelected) {
        return(
            <Course />
        )
    } else {
        return (
            <section className={`${styled.home} ${styled.dashboardSection} ${styled.active}`} id="home">
                <div className={dashboard.header}>
                    <h1>Home</h1>
                    <h2>Welcome back, <span>{user.email}</span>.</h2>
                </div>
                {user.isPaid === 'true' ? <>
                    <div className={styled.card}>
                        <img src="./img/course.jpeg" alt="" loading='lazy' />
                        {courseArr.map(course => {
                            return <div className={styled.courseInfo}>
                                <h3>{course.title}</h3>
                                <button onClick={() => { dispatch(updateCourseSelected(true)); dispatch(updateCourseDetails(course)) }} className={styled.btn}>Resume →</button>
                            </div>
                        })}
                    </div>
                </> : <div className={styled.header}><p className={styled.paymentPrompt}> You do not have access to any courses at the moment. Please <button onClick={getSession} className={styled.payment}>complete the payment</button> to get access.</p></div>}
            </section>
        )
    }
}

export default Dashboard;