import styled from '../dashboard.module.css'

const Notification = (message, styled) => {
    const popup = document.createElement("div");
    popup.classList.add(styled.popup);
    popup.textContent = message;
    document.body.appendChild(popup);
    setTimeout(() => {
        popup.remove();
    }, 2000);
}

export default Notification;