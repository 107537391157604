import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateUser } from '../../features/user/userSlice';
import Notification from '../../utils/notification';
import validator from 'validator';
import styled from './register.module.css'

const Register = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setconfirmPassword] = useState('')
    const [error, setError] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate();

    async function checkAuth() {
        try {
            const check = await fetch(`${process.env.REACT_APP_BACKEND_URL}/protected`, { headers: { "Authorization": `Bearer ${sessionStorage.getItem("token")}` } })
            console.log('Check: ', check)
            if (check.ok) {
                const res = await check.json()
                if (res.msg === 'ok') {
                    if (res.user.isAdmin && res.user.isAdmin === 'true') navigate('/admin')
                    else navigate('/')
                }
            }
        } catch (err) {
            console.log(err);
            return;
        }
    }

    useEffect(() => {
        checkAuth();
    }, [])

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validator.isEmail(email)) {
            setError(false)
            const register = await fetch(`${process.env.REACT_APP_BACKEND_URL}/register`, { method: 'post', body: JSON.stringify({ email: email, password: password, role: 'user' }), headers: { 'Content-Type': 'application/json' } });
            const res = await register.json();
            console.log('result: ', res)
            if (res.msg && res.msg === 'ok') {
                sessionStorage.setItem('token', res.token)
                dispatch(updateUser(res.user))
                getSession()
            } else {
                setError(res.msg)
            }
        } else {
            console.log('Not valid email')
            setError('Please enter a valid email.')
            return
        }
    }

    async function getSession() {
        try {
            const check = await fetch(`${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`, { method: 'POST', body: JSON.stringify({ email }), headers: { "Authorization": `Bearer ${sessionStorage.getItem("token")}`, 'Content-Type': 'application/json' } })
            console.log('Check: ', check)
            if (check.ok) {
                const res = await check.json()
                console.log('Check: ', res)
                if (res.msg) window.location.replace(res.msg)
            } else {
                navigate('/login')
            }
        } catch (err) {
            console.log(err);
            navigate('/login')
            return;
        }
    }

    const handlePasswordValidation = (e) => {
        if (e.target.value !== password) {
            setError("Password doesn't match")
        } else {
            setError(false)
        }
        setconfirmPassword(e.target.value);
    }

    return (
        <>
            <video autoplay='autoplay' muted loop>
                <source src="./tradingVideo.mp4" type="video/mp4" />
            </video>
            <div className={styled.container}>
                <h1>Register</h1>
                <form action="" method="post" className={styled.registerForm}>
                    <div className={styled.formField}>
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} name="email" required />
                        <label for="email"><span>Email</span></label>
                    </div>
                    <div className={styled.formField}>
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} name="password" id="password" minlength="5" required />
                        <label for="password"><span>Password</span></label>
                    </div>
                    <div className={styled.formField}>
                        <input type="password" value={confirmPassword} onChange={handlePasswordValidation} name="cpassword" id="cpassword" minlength="5" required />
                        <label for="cpassword"><span>Confirm&nbsp;Password</span></label>
                    </div>
                    {error && <div style={{ color: 'red', fontSize: '1.5rem', textAlign: 'center' }}>{error}</div>}
                    <button className={styled.btn} type="submit" onClick={handleSubmit}>Proceed To Checkout</button>
                </form>
                <p>Already have an account? <a className={styled.registerLink} href="./login">Login</a>.</p>
            </div>
        </>
    )
}

export default Register;