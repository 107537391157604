import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateActiveVideo, updateCompletedVideos } from '../../features/course/courseSlice';
import Notification from '../../utils/notification';
import styled from './course.module.css'
import { videos } from './data';

const Course = () => {
    // const [activeVideo, setActiveVideo] = useState(0);
    const { user } = useSelector(state => state.user)
    const { activeVideo, completedVideos } = useSelector(state => state.course)
    // const [completedVideos, setCompletedVideos] = useState([])
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (user.smt_1 === "") return
        const completed = user.smt_1.split(",").map(Number)
        dispatch(updateCompletedVideos(completed))
    }, [])

    const handlePrevious = () => {
        console.log('prev')
        dispatch(updateActiveVideo(activeVideo-1))
    }
    
    const handleNext = () => {
        console.log('next')
        dispatch(updateActiveVideo(activeVideo+1))
    }
    
    const handleVideoStatus = async () => {
        if(completedVideos.includes(activeVideo)){
            const videoIndex = completedVideos.indexOf(activeVideo);
            let dataArr = [...completedVideos];
            dataArr.splice(videoIndex, 1)
            const updateVideoStatus = await fetch(`${process.env.REACT_APP_BACKEND_URL}/update-course-video-status`, { method: 'POST', body: JSON.stringify({ data: dataArr.toString(), email: user.email }), headers: { "Authorization": `Bearer ${sessionStorage.getItem("token")}`, 'Content-Type': 'application/json' } })
            const data = await updateVideoStatus.json()
            if (data.msg === "ok") {
                dispatch(updateCompletedVideos(dataArr))
            }
        } else {
            const dataArr = [...completedVideos, activeVideo]
            const updateVideoStatus = await fetch(`${process.env.REACT_APP_BACKEND_URL}/update-course-video-status`, { method: 'POST', body: JSON.stringify({ data: dataArr.toString(), email: user.email }), headers: { "Authorization": `Bearer ${sessionStorage.getItem("token")}`, 'Content-Type': 'application/json' } } )
            const data = await updateVideoStatus.json()
            dispatch(updateCompletedVideos([...completedVideos, activeVideo]))
            if(data.msg === "ok"){
                Notification(styled, 'Marked completed')
            }
        }
    }

    return (
        <div className={styled.videoSection}>
            <section className={styled.active} id="video1">
                <div className={styled.header}>
                    <div className={styled.controls}>
                        <button onClick={handlePrevious} disabled={activeVideo === 0 ? true : false} className={activeVideo === 0 ? styled.disabledBtn : styled.btn}>← Previous Lesson</button>
                        <button onClick={handleNext} disabled={activeVideo === videos.length - 1} className={activeVideo === videos.length - 1 ? styled.disabledBtn : styled.btn}>Next Lesson →</button>
                        {/* {activeVideo !== 0 && <button onClick={handlePrevious} className={styled.btn}>← Previous Lesson</button>}
                        {activeVideo !== videos.length - 1 && <button onClick={handleNext} className={styled.btn}>Next Lesson →</button>} */}
                    </div>
                </div>
                <div className={styled.videoArea}>
                    <div className={styled.videoPlayer}>
                        <iframe src={videos[activeVideo].url} loading="lazy" style={{position:'absolute', height: "100%", width: "100%"}} allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe>
                    </div>
                    <div className={styled.videoInfo}>
                        <h3 className={styled.videoTitle}>Smart Money Concepts : {videos[activeVideo].title}</h3>
                        <button onClick={handleVideoStatus} className={styled.btn}>{completedVideos.includes(activeVideo) ? 'Mark As Incomplete' : 'Mark As Complete'}</button>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Course;