import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'user',
    initialState: {user: '', darkmode: false},
    reducers: {
        updateUser: (state, action) => {
            state.user = action.payload;
        },
        updateDarkMode: (state, action) => {
            state.darkmode = action.payload
        }
    }
});

// this is for dispatch
export const { updateUser, updateDarkMode } = userSlice.actions;

// this is for configureStore
export default userSlice.reducer;